document.addEventListener("turbolinks:load", () => {
  if ($(".file-input").length) {
    $(".file-input").change((e) => {
      if (e.target.files.length === 1) {
        $(e.target).siblings(".file-name").text(e.target.files[0].name);
      } else if (e.target.files.length > 1) {
        let fileNames = $(e.target.files).map((index, element) => element.name).get().join(", ");
        $(e.target).siblings(".file-name").text(fileNames);
      }
    });
  }
});